










import { Component, Vue, Prop } from "vue-property-decorator";

import { AccountService, ProfileModel } from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import { UserAccountModel } from "@/core/models";

export class Type {}

@Component({
  components: {},
})
export default class ActivateAccountButtonCpt extends AppVue {
  @Prop({ default: () => new ProfileModel() }) model: ProfileModel;
  @Prop({ default: "" }) size: string;

  loading: boolean = false;

  async save() {
    const result = await AccountService.ActivateAccount(this.model);
    this.$emit("completed", result);
  }

  get isLoading() {
    return this.loading;
  }

  async created() {}

  async mounted() {}
}
