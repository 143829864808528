


























































import { Component, Vue, Prop } from "vue-property-decorator";

import { AccountService, ProfileModel } from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import { UserAccountModel } from "@/core/models";

@Component({
  components: {},
})
export default class SetPasswordButtonCpt extends AppVue {
  @Prop({ default: () => new ProfileModel() }) model: ProfileModel;
  @Prop({ default: "" }) size: string;
  dialogVisible = false;
  displayMessage = false;
  disabled = false;
  loading: boolean = false;
  message = "";
  mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})");

  rules = {
    newPassword: [
      {
        required: true,
        message: "The new password is required",
        trigger: "blur",
      },
      { min: 8, message: "Length should be at least 8 characters", trigger: "blur" },
    ],
    confirmPassword: [
      {
        validator: (rule: any, value: string, callback: any) => {
          if (value === this.form.newPassword) {
            callback();
          } else {
            callback(new Error("Passwords don't match"));
          }
        },
      },
    ],
  };
  form = {
    email: "",
    newPassword: "",
    confirmPassword: "",
    token: "",
  };
  async save() {
    this.loading = true;
    this.disabled = true;
    this.displayMessage = false;
    try {
      const passwordValidation = this.mediumRegex.test(this.form.newPassword);
      const passwordMatch = this.form.newPassword === this.form.confirmPassword;
      if (!passwordValidation) {
        this.message = "The password is not strong enough";
        this.displayMessage = true;
        this.loading = false;
        this.disabled = false;
        return;
      }
      if (!passwordMatch) {
        this.message = "Passwords don't match";
        this.displayMessage = true;
        this.loading = false;
        this.disabled = false;
        return;
      }
      this.form.email = this.model.loginEmail;

      const response = await AccountService.SetPassword(this.model.userId, this.form);

      this.notify("Success", "The account has been updated.", "success");
      this.dialogVisible = false;
    } catch (error: any) {
      this.displayMessage = true;
      this.message = "Error setting the password for this user.";
    } finally {
      this.loading = false;
      this.disabled = false;
    }
  }

  get isLoading() {
    return this.loading;
  }

  init() {
    this.disabled = false;
    this.displayMessage = false;
    this.form = {
      email: "",
      newPassword: "",
      confirmPassword: "",
      token: "",
    };
  }
  handleClose() {
    this.dialogVisible = false;
    this.form = {
      email: "",
      newPassword: "",
      confirmPassword: "",
      token: "",
    };
  }

  async created() {}

  async mounted() {
    this.init();
  }
}
