









import { Component, Vue, Prop } from "vue-property-decorator";
import { AccountService } from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import { UserAccountModel, EditProfileModel } from "@/core/models";

@Component({
  components: {},
})
export default class UpdateUserButtonCpt extends AppVue {
  @Prop({ default: () => new EditProfileModel() }) model: EditProfileModel;
  @Prop({ default: "" }) size: string;

  loading: boolean = false;

  get isValidProfile() {
    return (
      !!this.model &&
      !!this.model.emailAddress &&
      !!this.model.name &&
      !!this.model.lastname &&
      !!this.model.vatCode &&
      !!this.model.accountName &&
      !!this.model.accountCode
    );
  }

  async save() {
    if (!this.isValidProfile) {
      this.notify("Missing data", `Please complete all required fields`, "warning");
      return;
    }

    const result = await AccountService.UpdateUser(this.model);
    this.$emit("completed", result);
  }

  get isLoading() {
    return this.loading;
  }

  async created() {}

  async mounted() {}
}
