






















import { Component, Vue, Prop } from "vue-property-decorator";

import { AccountService, ProfileModel } from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import { UserAccountModel, EditProfileModel } from "@/core/models";

@Component({
  components: {},
})
export default class SetRoleButtonCpt extends AppVue {
  @Prop({ default: "" }) targetRole: string;
  @Prop({ default: () => new EditProfileModel() }) model: EditProfileModel;
  @Prop({ default: "" }) size: string;

  loading: boolean = false;
  get roleName() {
    return this.roleToName(this.targetRole);
  }
  modelRole() {
    if (!this.model.roles) {
      return false;
    }

    return !!this.model.roles.find((x) => x === this.targetRole);
  }
  get canBeMadeAdmin() {
    const isDovetail = this.model.loginEmail.indexOf("dovetail.ie") > 0;
    const isCis = this.model.loginEmail.indexOf("cisireland.com") > 0;
    return this.targetRole !== "admin" || (this.targetRole === "admin" && (isDovetail || isCis));
  }

  async addRole() {
    this.loading = true;
    const res = await AccountService.AddRole(this.model.userId, this.targetRole);
    if (res == null) {
      this.$notify({ title: "error", message: "Operation failed" });
      this.loading = false;
      return;
    }
    this.$emit("completed", res);
  }

  async revokeRole() {
    this.loading = true;
    const res = await AccountService.RevokeRole(this.model.userId, this.targetRole);
    if (res == null) {
      this.$notify({ title: "error", message: "Operation failed" });
      this.loading = false;
      return;
    }
    this.$emit("completed", res);
  }

  get isLoading() {
    return this.loading;
  }

  async created() {}

  async mounted() {}
}
